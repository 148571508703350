import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { Player, ControlBar, ReplayControl, ForwardControl, CurrentTimeDisplay, TimeDivider,
  PlaybackRateMenuButton, VolumeMenuButton } from 'video-react'; //LoadingSpinner
import HLSSource from 'components/Video/HLSSource.jsx';
import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';
import MainNavbar from "components/Navbars/MainNavbar.jsx";

class LessonView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isContentLoaded: false,
      content: {},
      next: {},
      prev: {},
    }
  }

  componentWillMount() {
    if(typeof localStorage.accessToken !== 'string' || localStorage.accessToken === undefined)
    {
      this.props.history.push('/');
    }
    else{
      this.loadContentData(this.props.match.params.id);
    }   
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.loadContentData(nextProps.match.params.id)
    }
  }

  loadContentData = (e) => {
    this.setState({
      error: false,
      loading: true,
      isContentLoaded: false
    });

    const data = JSON.stringify({
      contentid: e
    })

    axios.post(apiRoutes.API_URL + '/api/studentcourse/getcontenturl', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      },
      timeout: 7000
    })
    .then( response => {
      this.setState({
        error: false,
        loading: false,
        content: response.data.value.mainurl,
        prev: response.data.value.prevlesson[0],
        next: response.data.value.nextlesson[0],
        isContentLoaded: true
      });
    })
    .catch( error => {
      console.log(error.response.data.message);
      this.setState({
        error: true,
        loading: false,
        isContentLoaded: false
      });
      // this.showNotification('warning', error.response.data.message);
    })
  }

  renderPlayer() {
    if(this.state.isContentLoaded)
      return (
        <Player ref="player">
          <HLSSource
            isVideoChild
            src={apiRoutes.API_URL + this.state.content.url}  //'http://api.training.carbox.mn/video/lessons/6/playlist.m3u8'
            setLevel={this.setLevel}
          />
          <ControlBar>
            <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={10} order={1.2} />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            <VolumeMenuButton />
          </ControlBar>
        </Player>
      )
  }

  setLevel = (e) => {
    console.log('SetLevel', e);    
  }

  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
        <Container className='container-header-padding'>
        <h3 className="category-title-text">
          {this.state.content.name}
        </h3>
        <hr className="headerline" align='left'/>
          <Card className="lessonview-card">
            <CardBody className='lessonview-cardbody'>
              {
                this.renderPlayer()
              }
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              {
                this.state.prev != null ?
                <Link className="text-light"
                  to={`/lesson/` + this.state.prev.id}
                >
                  <span className="login-link-text">{`Өмнөх: ` + this.state.prev.name}</span>
                </Link>
                :
                ''
              }
            </Col>
            <Col className="text-right" xs="6">
              {
                this.state.next != null ?
                <Link
                  className="text-light"
                  to={`/lesson/` + this.state.next.id}
                >
                  <span className="login-link-text">{`Дараагийн: ` + this.state.next.name}</span>
                </Link>
                :
                ''
              }
            </Col>
          </Row>
        </Container>
        </main>
      </>
    );
  }
}

export default LessonView;