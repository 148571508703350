import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: false,
      loading: false,
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  handleLogin = (e) => {
    e.preventDefault();

    this.setState({
      error: false,
      loading: true
    });

    const data = JSON.stringify({
      username: this.state.username,
      password: this.state.password
    })

    // {
    //   "username":"mdavkharbayar@gmail.com",
    //   "password":"NUEPY"
    // }

    axios.post(apiRoutes.API_URL + '/api/courselogin/login', data, {
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 7000
    })
    .then( response => {
      localStorage.accessToken = response.data.value.token;
      localStorage.email = response.data.value.email;
      localStorage.firstname = response.data.value.firstname;
      localStorage.lastname = response.data.value.lastname;

      this.setState({
        error: false,
        loading: false,
      }, () => {
        this.props.history.push('/category');
      });
    })
    .catch( error => {
      console.log(error.response.data.message);
      this.setState({
        error: true,
        loading: false
      });
      this.showNotification('warning', error.response.data.message);
    })
  }

  responseFacebook = (response) => {
    console.log('FB', JSON.stringify({
      response
    }));
    
    axios.post(apiRoutes.API_URL+'/api/courselogin/fblogin', response, {
      'Content-Type': 'application/json'
    })
    .then(response => {
      localStorage.accessToken = response.data.value.token;
      this.props.history.push('/category');
    })
    .catch(error => {
      console.log(error.message);
      if(error.response.data){
        this.setState({
          error: true,
          errorText: error.response.data.message
        });
      }
      else {
        this.setState({
          error: true,
          errorText: 'Алдаа сервертэй холбогдоход алдаа гарлаа'
        });
      }
    })
  }

  showNotification = (type, message) => {
    var options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <>
        <main ref="main">
          <section className="loginpage-main-section">
            <NotificationAlert ref='notificationAlert'/>
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="loginpage-card">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <h3 className="login-title-text">
                          Нэвтрэх
                        </h3>
                        <hr className="headerline" align='center'/>
                      </div>
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className='login-input-group'>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className='input-borderradius'>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name='username' placeholder="Email" type="email" value={this.state.username} onChange={this.handleChange}  className='input-borderradius'/>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className='login-input-group'>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className='input-borderradius'>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name='password' placeholder="Password" type="password" autoComplete="off" value={this.state.password} onChange={this.handleChange} className='input-borderradius'/>
                          </InputGroup>
                        </FormGroup>
                        {/* <div className="custom-control custom-control-alternative custom-checkbox">
                          <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
                          <label className="custom-control-label" htmlFor=" customCheckLogin" >
                            <span>Remember me</span>
                          </label>
                        </div> */}
                        <div className="text-center">
                          <Button className="my-4 category-lesson-body-button button-borderradius" type="button"  onClick={this.handleLogin} disabled={this.state.loading}>
                            {
                              this.state.loading ? 
                              'Ачаалж байна ...' :                          
                              'Нэвтрэх'
                            }                            
                          </Button>
                        </div>
                      </Form>
                      <div className="text-center">
                        <div className='login-divider'>
                          <span style={{color: '#4e4a67', fontSize: '12px'}}>Эсвэл</span>
                        </div>
                        <FacebookLogin
                          appId="550548929041273"
                          fields="name,email,picture"
                          callback={this.responseFacebook}
                          cssClass="btn btn-facebook my-4 button-borderradius"
                          >
                        </FacebookLogin>
                      </div>
                      <Row className="mt-3">
                        <Col xs="6">
                          <Link
                            className="text-light"
                            to="/signup"
                          >
                            <span className="login-link-text">Бүртгүүлэх</span>
                          </Link>
                        </Col>
                        <Col className="text-right" xs="6">
                          <Link
                            className="text-light"
                            to="/resetpassword"
                          >
                            <span className="login-link-text">Нууц үгээ сэргээх</span>
                          </Link>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default LoginPage;
