import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { Container, Card, CardBody } from "reactstrap";

import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentWillMount(){
    // this.loadContractList();
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  loadContractList = () => {
    this.setState({
      error: false
    });

    axios.get(apiRoutes.API_URL + '/api/studentcourse/contractlist', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      }
    })
    .then( response => {
      console.log(response.data.value);
      this.setState({
        contractList: response.data.value
      })
    })
    .catch(error => {
      console.log(error.response.data);
      this.showNotification ('warning', error.response.data.message);
      this.setState({
        error: true
      });
    })
  }

  showNotification = (type, message) => {
    var options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
        <Container className='container-header-padding'>
          <NotificationAlert ref='notificationAlert'/>
          <Card className='loginpage-card' style={{marginBottom: '30px'}}>
            <CardBody>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Нэр</th>
                  <th scope="col">Гэрээний дугаар</th>
                  <th scope="col">Эхлэх огноо</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
              </tbody>
            </table>
            </CardBody>
          </Card>
        </Container>
        </main>
      </>
    );
  }
}

export default ProfilePage;