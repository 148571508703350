import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import CourseLesson from "./CourseLesson";

class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <>
        <Row className="justify-content-center">
          <Col lg="12">
          {/* <h3 className="h4 text-primary font-weight-bold mb-4">
            {this.props.course.name}
          </h3> */}
          </Col>
          <Row>
            {this.props.course.coursecontents.map((content, index) => 
              <CourseLesson key={index+1} content={content} toggle={this.props.toggle} setLesson={this.props.setLesson}/>
            )}
          </Row>
        </Row>
      </>
    );
  }
}

export default Course;
