import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';


class ResetPasswordPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      error: false,
      loading: false,
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  handlePasswordChange = (e) => {
    e.preventDefault();

    this.setState({
      error: false,
      loading: true
    });

    const data = JSON.stringify({
      email: this.state.username,
    })

    axios.post(apiRoutes.API_URL + ' /api/courselogin/resetpassword', data, {
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 7000
    })
    .then( response => {
      this.setState({
        error: false,
        loading: false,
      }, () => {
        this.props.history.push('/login');
      });
    })
    .catch( error => {
      // console.log(error.response.data.message);      
      this.setState({
        error: true,
        loading: false
      });
      this.showNotification ('warning', error.response.data.message);
    })
  }

  showNotification = (type, message) => {
    var options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <>
        <main ref="main">
          <section className="loginpage-main-section">
            <NotificationAlert ref='notificationAlert'/>
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="loginpage-card">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <h3 className="login-title-text">
                          Нууц үгээ сэргээх
                        </h3>
                        <hr className="headerline" align='center'/>
                      </div>
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className='login-input-group'>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className='input-borderradius'>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name='username' placeholder="Email" type="email" value={this.state.username} onChange={this.handleChange} className='input-borderradius'/>
                          </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                          <Button className="my-4 category-lesson-body-button button-borderradius" type="button"  onClick={this.handlePasswordChange} disabled={this.state.loading}>
                            {
                              this.state.loading ? 
                              'Ачаалж байна ...' :                          
                              'Нууц үгээ сэргээх'
                            }                            
                          </Button>
                        </div>
                        <Row className="mt-3">
                          <Col xs="6">
                            <Link
                              className="text-light"
                              to="/login"
                            >
                              <span className="login-link-text">Нэвтрэх</span>
                            </Link>
                          </Col>
                          <Col className="text-right" xs="6">
                            <Link
                              className="text-light"
                              to="/signup"
                            >
                              <span className="login-link-text">Бүртгүүлэх</span>
                            </Link>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        {/* <SimpleFooter /> */}
      </>
    );
  }
}

export default ResetPasswordPage;