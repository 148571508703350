import React, { Component } from "react";
import { Container } from "reactstrap";

class HeaderSection extends Component {
  render() {
    return (
      <>
        <section className="section header-section">
          <Container className="header-section-container">
            <span className='header-section-title'>
              ХЭЛНИЙ СУРГАЛТЫН ТУХАЙ
            </span>
            <span className='header-section-description'>
              Хэлтэй бол хөлтэй гэдэг үг бий. Хүн төрөлхтөний соёлын болон хэлний хил хязгаар 
              даяршиж, улам ойртож буй өнөө цагт та эх хэлнээсээ гадна өөр нэгэн хэл эзэмших 
              зайлшгүй шаардлагатай болсон. Тиймээс богино хугацаанд мэргэжилийн өндөр түвшинд 
              чанартай мэдлэг олж авахад тань туслая.
            </span>
          </Container>
        </section>
      </>
    );
  }
}

export default HeaderSection;