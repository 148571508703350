import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Player, ControlBar, ReplayControl, ForwardControl, CurrentTimeDisplay, TimeDivider,
  PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';
  import HLSSource from 'components/Video/HLSSource.jsx';

import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";
import CourseInfoListItem from "components/CourseInfoListItem/CourseInfoListItem.jsx";
import CourseInfoListCardItem from "components/CourseInfoListItem/CourseInfoListCardItem.jsx";
import CourseHeaderSection from "components/HeaderSection/CourseHeaderSection.jsx";

class CourseInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      courseInfo: {},
      coursecontents: [],
      lesson: '',
      modal: false,
    }
  }

  componentWillMount(){    
    this.loadLessonList(this.props.match.params.id);
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  loadLessonList = (e) => {
    this.setState({
      error: false
    });

    axios.get(apiRoutes.API_URL + '/api/course/' + e, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.accessToken}`
      }
    })
    .then( response => {
      this.setState({
        courseInfo: response.data.value,
        coursecontents: response.data.value.coursecontents
      }, () => {
        console.log(this.state.courseInfo);          
      })
    })
    .catch(error => {
      this.setState({
        error: true
      });
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  setLesson = (e) => {
    console.log(e);    
    this.setState({
      lesson: e
    }, () => {
      this.toggle()
    })
  }

  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
        {/* <CourseHeaderSection coursedata={this.state.courseInfo}/> */}
        <Container className='container-header-padding'>
          <Row>
            <Col lg="8" sm='12'>
              <h3 className="courseinfo-title-text">
                {this.state.courseInfo.name}
              </h3>
              <hr className="headerline" align='left'/>
              { 
                this.state.courseInfo.image != null ?
                <img alt='...' src={apiRoutes.API_URL + this.state.courseInfo.image} className='courseinfo-lesson-image'/>
                : ''
              }
              <Card className="courseinfo-lesson-card">
                <CardBody className="courseinfo-lesson-cardbody">
                  {/* <h6 className="course-lesson-cardbody-title">
                    {this.state.courseInfo.name}
                  </h6> */}
                  <p dangerouslySetInnerHTML={{ __html: this.state.courseInfo.content }} />
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" sm='12'>
              <h3 className="courseinfo-title-text">
                Хичээлүүд
              </h3>
              <hr className="headerline" align='left'/>
              {
                this.state.coursecontents.map((content, index) => 
                  <CourseInfoListCardItem key={index} content={content} toggle={this.toggle} setLesson={this.setLesson}/>
                )
              }
              {/* <Card className="courseinfo-lesson-card">
                <CardBody className="courseinfo-lesson-cardbody">
                  {
                    this.state.coursecontents.map((content, index) => 
                      <CourseInfoListItem key={index} content={content} toggle={this.toggle} setLesson={this.setLesson}/>
                    )
                  }
                </CardBody>
              </Card> */}
              {/* <Card className="courseinfo-lesson-card">
                <CardBody className="courseinfo-lesson-cardbody">
                  <h6 className="course-lesson-cardbody-title">
                    {this.state.courseInfo.name}
                  </h6>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>{this.state.lesson.name}</ModalHeader>
            <ModalBody className='courseinfo-modal-cardbody'>
                <Player>
                  <HLSSource
                    isVideoChild
                    src={apiRoutes.API_URL + this.state.lesson.url}
                  />
                  <ControlBar>
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={30} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                    <VolumeMenuButton />
                  </ControlBar>
                </Player>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" size="sm" onClick={this.toggle}>Хаах</Button>
            </ModalFooter>
          </Modal>
        </Container>
        </main>
      </>
    );
  }
}

export default CourseInfo;