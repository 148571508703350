import React, { Component } from "react";
import { Link } from 'react-router-dom';
import apiRoutes from 'utils/apiRoutes';
import { Row, Col, Card, CardBody, Button } from "reactstrap";

class CourseInfoListItem extends Component {
  render() {
    return (
      <>
        <div className='courseinfo-lesson-listitem' onClick={this.props.content.istrial !== 1 ? () => this.props.setLesson(this.props.content) : ()=>{}}>
          <Row>
            <Col lg="3" sm='12'>
              <div className='courseinfo-lesson-imagecontainer'>
                { 
                  this.props.content.istrial !== 1 ?
                    <i className='fa fa-lock' style={{marginRight: '2px'}} />
                  : ''
                }
                { 
                  this.props.content.image != null ?
                    <img alt='...' src={apiRoutes.API_URL + this.props.content.image} className='courseinfo-lesson-body-image'
                      onMouseEnter={(e) => {this.props.content.gifimage != undefined ? e.target.src=this.src=apiRoutes.API_URL + this.props.content.gifimage 
                        : e.target.src=this.src=apiRoutes.API_URL + this.props.content.image}}
                      onMouseOut={(e) => {e.target.src=this.src=apiRoutes.API_URL + this.props.content.image}}
                    />
                  : ''
                }
              </div>
            </Col>
            <Col lg="9" sm='12'>
              <h6 className="courseinfo-lesson-title">
                {this.props.content.name}
              </h6>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default CourseInfoListItem;