import React, { Component } from "react";
import { Link } from 'react-router-dom';
import apiRoutes from 'utils/apiRoutes';
import { Row, Col, Card, CardBody, Button } from "reactstrap";

class CourseInfoListCardItem extends Component {
  render() {
    return (
      <>
        <div className='courseinfo-lesson-listitem' onClick={this.props.content.istrial === 1 ? () => this.props.setLesson(this.props.content) : ()=>{}}>
        <Card className={this.props.content.istrial === 1 ? 'courseinfo-lessonview-card' : 'courseinfo-lessonview-card courseinfo-lessonview-card-disabled'}>
          <div className='courseinfo-lesson-imagecontainer'>
            { 
              this.props.content.image != null ?
                <img alt='...' src={apiRoutes.API_URL + this.props.content.image} className='courseinfo-lessonview-card-image'
                  onMouseEnter={(e) => {this.props.content.gifimage != undefined ? e.target.src=this.src=apiRoutes.API_URL + this.props.content.gifimage 
                    : e.target.src=this.src=apiRoutes.API_URL + this.props.content.image}}
                  onMouseOut={(e) => {e.target.src=this.src=apiRoutes.API_URL + this.props.content.image}}
                />
              : ''
            }
          </div>
          <CardBody className="courseinfo-lessonview-cardbody">
            <h6 className="courseinfo-lesson-title">
              {this.props.content.name}
            </h6>
          </CardBody>
        </Card>
        </div>
      </>
    );
  }
}

export default CourseInfoListCardItem;