import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import CategoryCourse from './CategoryCourse.jsx';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <>
        <Row className="justify-content-center">
          <Col lg="12">
          <span className="category-title-text">
            {this.props.category.name}
          </span>
          <hr className="headerline" align='left'/>
          <Row>
            {
              this.props.category.courses.map((course, index) => 
                <CategoryCourse key={index + 1} course={course} showNotification={this.props.showNotification} route={this.props.route}/>
              )
            }
          </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Category;
