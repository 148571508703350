import React, { Component } from "react";
import Course from 'components/Course/Course.jsx'
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Player, ControlBar, ReplayControl, ForwardControl, CurrentTimeDisplay, TimeDivider,
  PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';
import HLSSource from 'components/Video/HLSSource.jsx';

import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";
import CourseHeaderSection from "components/HeaderSection/CourseHeaderSection.jsx";

class CoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      courseList: [],
      modal: false,
      lesson: '',
    }
  }

  componentWillMount(){    
    this.loadLessonList(this.props.match.params.id);
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  loadLessonList = (e) => {
    this.setState({
      error: false
    });

    axios.get(apiRoutes.API_URL + '/api/course/' + e, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.accessToken}`
      }
    })
    .then( response => {
      console.log(response.data.value);
      
      this.setState({
        courseList: response.data.value
      })
    })
    .catch(error => {
    //   this.props.showNotification ('warning', error.response.data.message);
      this.setState({
        error: true
      });
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  setLesson = (e) => {
    this.setState({
      lesson: e
    }, () => {
      this.toggle()
    })
  }

  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
        <CourseHeaderSection coursedata={this.state.courseList[0]}/>
        <Container>
          {
            this.state.courseList.map((course, index) => 
              <Course key={index + 1} course={course} toggle={this.toggle} setLesson={this.setLesson}/>
            )
          }
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>{this.state.lesson.name}</ModalHeader>
            <ModalBody>
                <Player>
                  <HLSSource
                    isVideoChild
                    src={apiRoutes.API_URL + this.state.lesson.url}
                  />
                  <ControlBar>
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={30} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                    <VolumeMenuButton />
                  </ControlBar>
                </Player>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" size="sm" onClick={this.toggle}>Хаах</Button>
            </ModalFooter>
          </Modal>
        </Container>
        </main>
      </>
    );
  }
}

export default CoursePage;