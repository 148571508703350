import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';

class CategoryCourse extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false
    }
  }

  handleBuyCourse = (courseid) => {
    this.setState({
      error: false,
      loading: true
    });

    const data = JSON.stringify({
      courseid: courseid
    })
    axios.post(apiRoutes.API_URL + '/api/studentcourse/checkoutcourse', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      },
      timeout: 7000
    })
    .then( response => {
      console.log('Response log', response.data);
      this.props.showNotification('success', response.data.message);
      this.setState({
        error: false,
        loading: false,
      });
    })
    .catch( error => {
      this.setState({
        error: true,
        loading: false
      });
      this.props.showNotification('warning', error.response.data.message);
    })
  }

  render() {
    return (
      <>
        <Col lg='4'>
          <Card className='category-lesson-card'>
            <img alt='...' src={apiRoutes.API_URL + this.props.course.image} className='category-lesson-body-image' />
            <CardBody className='category-lesson-cardbody'>
              {
                this.props.course.watchstatus != 1 && this.props.route == '/course/' ?
                <h6 className='category-lesson-cardbody-title'>
                  {this.props.course.name}
                </h6>
                : 
                <Link to={this.props.route + this.props.course.id}>
                  <h6 className='category-lesson-cardbody-title-enabled'>
                    {this.props.course.name}
                  </h6>
                </Link>
              }
              <div>
                <Row className='mt-3'>
                  <Col xs='6'>
                    <Badge color='primary' pill className='mr-1'>
                      {this.props.course.price}
                    </Badge>
                  </Col>
                  <Col className='text-right' xs='6'>
                    <Button value={this.props.course.id} size='sm' className='category-lesson-body-button' onClick={() => this.handleBuyCourse(this.props.course.id)} disabled={this.state.loading}>
                      Худалдан авах
                    </Button>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default CategoryCourse;
