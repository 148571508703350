import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import Category from 'components/Category/Category.jsx'
import { Container } from "reactstrap";

import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";
import HeaderSection from "components/HeaderSection/HeaderSection.jsx";

class MyCategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categoryList: []
    }
  }

  componentWillMount(){
    if(typeof localStorage.accessToken !== 'string' || localStorage.accessToken === undefined)
    {
      this.props.history.push('/');
    }
    else{
      this.loadCategoryList();
    }    
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  loadCategoryList = () => {
    this.setState({
      error: false
    });

    axios.get(apiRoutes.API_URL + '/api/studentcourse/mycourselist', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      }
    })
    .then( response => {
      console.log('Category data', response.data.value);      
      this.setState({
        categoryList: response.data.value
      })
    })
    .catch(error => {
      console.log (error.response.data.message);
      this.showNotification ('warning', error.response.data.message);
      this.setState({
        error: true
      });
    })
  }

  showNotification = (type, message) => {
    var options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
        <HeaderSection />
        <NotificationAlert ref='notificationAlert'/>
        <Container>
          {
            this.state.categoryList.map((category, index) => 
              <Category key={index + 1} category={category} showNotification={this.showNotification} route={'/course/'}/>
            )
          }
        </Container>
        </main>
      </>
    );
  }
}

export default MyCategoryPage;