import React from "react";
import ReactDOM from "react-dom";
import App from './App.jsx';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";
import "assets/css/styles.css";
import '../node_modules/video-react/dist/video-react.css'; 

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

// http://digitalexpert.golomtbank.com/