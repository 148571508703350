import React, { Component } from 'react';
import Hls from 'hls.js';

export default class HLSSource extends Component {
  constructor(props, context) {
    super(props, context);
    this.hls = new Hls({ 
      enableWorker: false,
      capLevelToPlayerSize: true,
      debug: true,
      xhrSetup: async (xhr, url) => {
        const accessToken = '12345678'; //await getAccessToken()
        xhr.open('GET', url, true);
        // xhr.withCredentials = true;
        // xhr.setRequestHeader('Accept', '*/*');
        // xhr.setRequestHeader('Accept-Encoding', 'gzip, deflate');
        // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        // xhr.setRequestHeader('Access-Control-Request-Method', 'GET');
        // xhr.setRequestHeader('Access-Control-Request-Headers', 'access-control-allow-origin,authorization');
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
     }
    });
  }

  componentDidMount() {
    const { src, video } = this.props;
    // load hls video source base on hls.js
    if (Hls.isSupported()) {
      this.hls.loadSource(src);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    }
  }

  componentWillUnmount() {
    // destroy hls video source
    if (this.hls) {
      this.hls.destroy();
    }
  }

  render() {
    return (
      <source
        src={this.props.src}
        type={this.props.type || 'application/x-mpegURL'}
      />
    );
  }
}