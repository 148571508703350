import React, { Component } from "react";

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";
import HeaderSection from "components/HeaderSection/HeaderSection.jsx";

class HomePage extends Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
        <HeaderSection />
        </main>
      </>
    );
  }
}

export default HomePage;
