import React, { Component } from "react";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { Container, Card, CardBody, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";

class ContractPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contractList: [],
      showModal: false,
      paymentInfo: {}
    }
  }

  componentWillMount(){
    this.loadContractList();
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  loadContractList = () => {
    this.setState({
      error: false
    });

    axios.get(apiRoutes.API_URL + '/api/studentcourse/contractlist', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      }
    })
    .then( response => {
      console.log(response.data.value);
      this.setState({
        contractList: response.data.value
      })
    })
    .catch(error => {
      console.log(error.response.data);
      this.showNotification ('warning', error.response.data.message);
      this.setState({
        error: true
      });
    })
  }

  showNotification = (type, message) => {
    var options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  showPaymentInfo = (id) => {
    this.setState({
      error: false,
      loading: true
    });

    const data = JSON.stringify({
      contractid: id
    })
    axios.post(apiRoutes.API_URL + '/api/studentcourse/contractpayinfo', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      },
      timeout: 7000
    })
    .then( response => {
      // console.log('Response log', response.data);
      // this.props.showNotification('success', response.data.message);
      this.setState({
        error: false,
        loading: false,
        showModal: true,
        paymentInfo: response.data.value
      });
    })
    .catch( error => {
      this.setState({
        error: true,
        loading: false,
        showModal: false,
        paymentInfo: {}
      });
      this.props.showNotification('warning', error.response.data.message);
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
        <Container className='container-header-padding'>
          <NotificationAlert ref='notificationAlert'/>
          <Card className='contractpage-card' style={{marginBottom: '30px'}}>
            <CardBody>
            <table className="table">
              <thead>
                <tr align='center'>
                  <th scope="col">#</th>
                  <th scope="col">Гэрээний дугаар</th>
                  <th scope="col">Хичээл</th>
                  <th scope="col">Дүн</th>
                  <th scope="col">Төлсөн дүн</th>
                  <th scope="col">Эхлэх огноо</th>
                  <th scope="col">Төлөв</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.contractList.map((contract, index) => 
                    <tr key={index} onClick={() => this.showPaymentInfo(contract.id)}>
                      <th scope="row" align='center'>{index + 1}</th>
                      <td align='center'>{contract.contractcode}</td>
                      <td><Link to={'/courseinfo/' + contract.course.id}>{contract.course.name}</Link></td>
                      <td align='right'>{Number(contract.amount).toLocaleString()}</td>
                      <td align='right'>{Number(contract.totalamount).toLocaleString()}</td>
                      <td>{contract.created_at}</td>
                      <td align='center'>{contract.status === 1 ? <Badge color="success">Төлсөн</Badge> : <Badge color="warning">Төлөгдөөгүй</Badge>}</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            </CardBody>
            <Modal isOpen={this.state.showModal} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle}>{this.state.paymentInfo.name}</ModalHeader>
              <ModalBody className='courseinfo-modal-cardbody'>
                
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" size="sm" onClick={this.toggle}>Хаах</Button>
              </ModalFooter>
            </Modal>
          </Card>
        </Container>
        </main>
      </>
    );
  }
}

export default ContractPage;