import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import Category from 'components/Category/Category.jsx'
import { Container, Row, Col } from "reactstrap";

import apiRoutes from 'utils/apiRoutes';
import axios from 'axios';

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";
import HeaderSection from "components/HeaderSection/HeaderSection.jsx";

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categoryList: []
    }
  }

  componentWillMount(){
    this.loadCategoryList();
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  loadCategoryList = () => {
    this.setState({
      error: false
    });

    axios.get(`${apiRoutes.API_URL}/api/category`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.accessToken}`
      }
    })
    .then( response => {
      this.setState({
        categoryList: response.data.value
      })
    })
    .catch(error => {
      this.showNotification ('warning', error.response.data.message);
      this.setState({
        error: true
      });
    })
  }

  showNotification = (type, message) => {
    var options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
        <HeaderSection />
        <NotificationAlert ref='notificationAlert'/>
        <Container>
          {
            this.state.categoryList.map((subcategory, index) => {              
              return subcategory.coursesubcategories.length > 0 ? <><Row className="justify-content-center">
                  <Col lg="12">
                    <span className="category-title-text">
                      {subcategory.name}
                    </span>
                    <hr className="headerline" align='left'/>
                  </Col>
                </Row>
                {
                  subcategory.coursesubcategories.map((category, index) =>
                    <Category key={index + 1} category={category} showNotification={this.showNotification}  route={'/courseinfo/'}/>
                  ) 
                }
                </>
                : <></>
              }
            )
          }
        </Container>
        </main>
      </>
    );
  }
}

export default CategoryPage;
