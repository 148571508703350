import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { UncontrolledCollapse, NavbarBrand, Navbar, NavItem, Nav, Container, Row, Col, 
  DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";

class MainNavbar extends React.Component {

  setLoginInfo = () => {
    if(typeof localStorage.accessToken !== 'string' || localStorage.accessToken === undefined)
    {
      return <NavItem className="nav-item ml-2">
          <Link className="navlink-item" to="/login">
            Нэвтрэх
          </Link>
        </NavItem>
    }
    else
    {
      return <>
        {/* <NavItem className="nav-item ml-2 nav-item dropdown">
          <Link className="navlink-item" to="/mylesson">
            Миний хичээлүүд
          </Link>
        </NavItem>
        <NavItem className="nav-item ml-2 nav-item dropdown" >
          <Link className="navlink-item" to="#" onClick={this.signOut}>
            Гарах
          </Link>
        </NavItem> */}
        <UncontrolledDropdown className="navlink-item">
          <DropdownToggle className='nav-item-back navlink-item main-navbar'>
            <span className="navlink-item">Хэрэглэгч</span>
          </DropdownToggle>
          <DropdownMenu className="navlink-item">
            <DropdownItem to="/myprofile" tag={Link}>
              Профайл
            </DropdownItem>
            <DropdownItem to="/mylesson" tag={Link}>
              Миний хичээлүүд
            </DropdownItem>
            <DropdownItem to="/contract" tag={Link}>
              Гэрээнүүд
            </DropdownItem>
            <DropdownItem to="#" tag={Link} onClick={this.signOut}>
              Гарах
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
       </>
    }
  }

  signOut = () => {
    localStorage.removeItem('accessToken');
  }
  
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-transparent navbar-light main-navbar fixed-top"
            expand="lg"
            id="navbar-main"
          >
            <Container >
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/logo.jpg")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse navbar toggler="#navbar_global" className='nav-item-back'>
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/logo.jpg")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="align-items-lg-center ml-lg-auto ml-2" navbar>
                  <NavItem className="nav-link-inner--text ml-2">
                    <Link className="nav-link-inner--text ml-2 navlink-item" to="/aboutus">
                      Бидний тухай
                    </Link>
                  </NavItem>
                  <NavItem className="nav-item nav-link-inner--text ml-2">
                    <Link className="nav-link-inner--text ml-2 navlink-item" to="/category">
                      Хичээл
                    </Link>
                  </NavItem>
                  {
                    this.setLoginInfo()
                  }
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default MainNavbar;