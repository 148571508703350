import React, { Component } from "react";
import { Link } from 'react-router-dom';
import apiRoutes from 'utils/apiRoutes';
import { Col, Card, CardBody, Button } from "reactstrap";

class CourseLesson extends Component {
  render() {
    return (
      <>
        <Col lg="4">
          <Link to={'/lesson/' + this.props.content.id}>
          <Card className="course-lesson-card">
            { 
              this.props.content.image != null ?
                <img alt='...' src={apiRoutes.API_URL + this.props.content.image} className='course-lesson-body-image'
                  onMouseEnter={(e) => {this.props.content.gifimage != undefined ? e.target.src=this.src=apiRoutes.API_URL + this.props.content.gifimage 
                    : e.target.src=this.src=apiRoutes.API_URL + this.props.content.image}}
                  onMouseOut={(e) => {e.target.src=this.src=apiRoutes.API_URL + this.props.content.image}}
                />
              : ''
            }
            <CardBody className="course-lesson-cardbody">
              <Link to={'/lesson/' + this.props.content.id}>
                <h6 className="course-lesson-cardbody-title">
                  {this.props.content.name}
                </h6>
              </Link>
              {/* <Button size="sm" className='category-lesson-body-button' onClick={() => this.props.setLesson(this.props.content)}>Үзэх</Button> */}
            </CardBody>
          </Card>
          </Link>
        </Col>
      </>
    );
  }
}

export default CourseLesson;