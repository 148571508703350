import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import HomePage from "views/HomePage/HomePage.jsx";
import CategoryPage from "views/CategoryPage/CategoryPage.jsx";
import ContractPage from "views/ContractPage/ContractPage.jsx";
import MyCategoryPage from "views/MyCategoryPage/MyCategoryPage.jsx";
import CoursePage from "views/CoursePage/CoursePage.jsx";
import CourseInfo from "views/CourseInfo/CourseInfo.jsx";
import LessonView from "views/LessonView/LessonView.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
import ResetPasswordPage from "views/ResetPasswordPage/ResetPasswordPage.jsx";
import RegisterPage from "views/RegisterPage/RegisterPage.jsx";
import ProfilePage from "views/ProfilePage/ProfilePage.jsx";

import Index from "views/Index.jsx";
import Landing from "views/examples/Landing.jsx";
import Profile from "views/examples/Profile.jsx";

class App extends Component {

  showNotification = (type, message) => {
    var options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 3
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/home" exact render={props => <HomePage {...props} showNotification={this.showNotification}/>} />
          <Route path="/category" exact render={props => <CategoryPage {...props} showNotification={this.showNotification}/>} />
          <Route path="/courseinfo/:id" render={props => <CourseInfo {...props} showNotification={this.showNotification}/>} />
          <Route path="/mylesson" exact render={props => <MyCategoryPage {...props} showNotification={this.showNotification}/>} />
          <Route path="/course/:id" render={props => <CoursePage {...props} showNotification={this.showNotification}/>} />
          <Route path="/lesson/:id" render={props => <LessonView {...props} showNotification={this.showNotification}/>} />
          <Route path="/contract" exact render={props => <ContractPage {...props} showNotification={this.showNotification}/>} />          
          <Route path="/login" exact render={props => <LoginPage {...props} showNotification={this.showNotification}/>} />
          <Route path="/signup" exact render={props => <RegisterPage {...props} showNotification={this.showNotification}/>} />
          <Route path="/resetpassword" exact render={props => <ResetPasswordPage {...props} showNotification={this.showNotification}/>} />
          <Route path="/myprofile" exact render={props => <ProfilePage {...props} showNotification={this.showNotification}/>} />
          
          <Route path="/profile" exact render={props => <Profile {...props} showNotification={this.showNotification}/>} />
          <Route path="/landing-page" exact render={props => <Landing {...props} showNotification={this.showNotification}/>} />
          <Route path="/index" exact render={props => <Index {...props} showNotification={this.showNotification}/>} />

          <Redirect to="/category" showNotification={this.showNotification}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;